// Types
import { TProductList } from 'types';

export type IProductConfig = {
  /** product which others are dependent on */
  [Property in TProductList]: {
    /** when the product is on, these products should be off */
    On: TProductList[];
    /** when the product is off, these products should be off */
    Off: TProductList[];
    /** when the product is updated, these products should also be updated */
    Update?: TProductList[];
    /** a list of products that should be off when a high speed is selected */
    HighSpeed?: TProductList[];
    /** allows for a unique rule, the string array will correspond with valid product names */
    CustomSpeeds?: Partial<{ [Property in TProductList]: string[] }>;
    /** a list of products that should be added when a product is turned on */
    Add?: TProductList[];
    /** allows for a unique rule - specific product level dependencies, the string arrays will correspond with valid product names */
    ProductLevelDeps?: Partial<{
      [Property in TProductList]: {
        Add: string[];
        Update: string[];
        Remove: string[];
      };
    }>;
  };
};

export const dependencyConfig: Partial<IProductConfig> = {
  'Enterprise Internet': {
    On: [],
    Off: ['MNE WiFi'],
  },
  'DFI Speed': {
    On: [],
    Off: [
      'DFI Static IP',
      'Managed WiFi',
      'Wireless Backup',
      'MRS',
      'Demarc Extension',
      'Demarc Extension Promo',
      'Trunk PRI',
      'Trunk SIP',
    ],
    Update: ['MRS'],
    HighSpeed: ['MRS'],
    CustomSpeeds: { 'Wireless Backup': ['20M', '30M', '50M', '100M', '200M'] },
    Add: ['Demarc Extension', 'Demarc Extension Promo'],
  },
  'Ethernet Speed': {
    On: [],
    Off: ['Demarc Extension', 'Demarc Extension Promo'],
    HighSpeed: ['MRS'],
    Add: ['Demarc Extension', 'Demarc Extension Promo'],
  },
  'MNE Network Speed': {
    On: [
      'Static IP',
      'Managed WiFi',
      'Wireless Backup',
      'MRS',
      'ENE Network Speed',
      'Virtual FortiGate',
      'ENE WiFi',
      'ENE Switches',
      'ENE Device Management',
    ],
    Off: [
      'MNE Advanced Analytics',
      'MNE Virtual MX',
      'Insights Licensing',
      'SD-WAN Plus Licensing',
      'MNE WiFi',
      'MNE Cameras',
      'MNE Cloud Storage',
      'MNE Remote Workers',
      'MNE Sensors',
      'MNE Switches',
    ],
    Update: ['Insights Licensing', 'SD-WAN Plus Licensing'],
  },
  'RC Seats': {
    On: ['MNE Switches'],
    Off: ['MNE Switches'],
  },
  'MNE Device Management': {
    On: [
      'ENE Network Speed',
      'Virtual FortiGate',
      'ENE WiFi',
      'ENE Switches',
      'ENE Device Management',
    ],
    Off: ['MNE Onboarding Fee'],
    Add: ['MNE Onboarding Fee'],
  },
  'ENE Network Speed': {
    On: [
      'Static IP',
      'Managed WiFi',
      'Wireless Backup',
      'MRS',
      'MNE Network Speed',
      'MNE Advanced Analytics',
      'MNE Virtual MX',
      'Insights Licensing',
      'SD-WAN Plus Licensing',
      'MNE Device Management',
    ],
    Off: [
      'ENE Switches',
      'ENE WiFi',
      'MNE WiFi',
      'MNE Cameras',
      'MNE Cloud Storage',
      'MNE Remote Workers',
      'MNE Sensors',
      'MNE Switches',
    ],
  },
  'ENE Device Management': {
    On: [
      'MNE Network Speed',
      'MNE Advanced Analytics',
      'MNE Virtual MX',
      'MNE WiFi',
      'MNE Cameras',
      'MNE Cloud Storage',
      'MNE Switches',
      'MNE Remote Workers',
      'MNE Sensors',
      'MNE Device Management',
    ],
    Off: ['ENE Onboarding Fee'],
    Add: ['ENE Onboarding Fee'],
  },
  'Trunk PRI': {
    On: ['Trunk SIP'],
    Add: ['DFI Speed'],
    Off: [],
  },
  'Trunk SIP': {
    On: ['Trunk PRI'],
    Add: ['DFI Speed'],
    Off: [],
  },
  'Wireless Internet': {
    On: ['Wireless Backup'],
    Off: [],
  },
  'Wireless Backup': {
    On: ['Wireless Internet'],
    Off: [],
  },
  Location: {
    On: [],
    Off: ['Package', 'Equipment', 'Add-Ons'],
    Update: ['Add-Ons'],
    Add: ['Package', 'Equipment'],
  },
  'Managed WiFi': {
    On: [
      'MNE Network Speed',
      'MNE Virtual MX',
      'Insights Licensing',
      'SD-WAN Plus Licensing',
      'MNE Advanced Analytics',
      'MNE WiFi',
      'MNE Cameras',
      'MNE Cloud Storage',
      'MNE Switches',
      'MNE Remote Workers',
      'MNE Sensors',
      'ENE Network Speed',
      'Virtual FortiGate',
      'ENE WiFi',
      'ENE Switches',
      'Wireless Internet Service Plan',
      'Wireless Internet Device Selection',
    ],
    Off: [],
  },
  'SD-WAN Plus Licensing': {
    On: ['Insights Licensing'],
    Off: [],
    Add: [],
  },
  'Insights Licensing': {
    On: ['SD-WAN Plus Licensing'],
    Off: [],
  },
  'Wireless Internet Service Plan': {
    On: [],
    Off: ['Wireless Internet Device Selection'],
    Add: ['Wireless Internet Device Selection'],
    Update: ['Wireless Internet Device Selection'],
  },
  'Wireless Internet Device Selection': {
    On: [],
    Off: ['Wireless Internet Service Plan'],
  },
  'RC Phones / Devices': {
    On: [],
    Off: [],
    ProductLevelDeps: {
      'RC Additional NRC': {
        Add: ['Per Phone Shipping Fee', 'Per Phone Activation Fee'],
        Update: ['Per Phone Shipping Fee', 'Per Phone Activation Fee'],
        Remove: [
          'Per Phone Shipping Fee',
          'Per Phone Activation Fee',
          'On Site Professional Install',
        ],
      },
    },
  },
  'MNE WiFi': {
    On: ['Virtual FortiGate'],
    Off: [],
    ProductLevelDeps: {
      'MNE WiFi': {
        Add: ['Indoor Antenna', 'Outdoor Antenna'],
        Update: ['Indoor Antenna', 'Outdoor Antenna'],
        Remove: ['Indoor Antenna', 'Outdoor Antenna'],
      },
    },
  },
  'MNE Switches': {
    On: ['Virtual FortiGate'],
    Off: [],
  },
  'MNE Cameras': {
    On: ['Virtual FortiGate'],
    Off: [],
  },
  'MNE Remote Workers': {
    On: ['Virtual FortiGate'],
    Off: [],
  },
  'MNE Sensors': {
    On: ['Virtual FortiGate'],
    Off: [],
  },
};
